<template>
  <b-row class="justify-content-center">
    <b-col md="6">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-form-group label="Name" label-for="category">
            <b-form-input
              id="category"
              placeholder="Name"
              v-model="form.category"
              required
            />
          </b-form-group>
          <b-form-group label="Parent Category" label-for="parent">
            <b-form-select
              id="parent"
              v-model="form.selected"
              :options="form.options"
            />
            <!-- Selected: <strong>{{ form.selected }}</strong> -->
          </b-form-group>
          <b-row>
            <b-col
              md="6"
              class="mt-1"
              v-if="permissions.includes('product-category-update')"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                @click="updateCategory()"
                block
              >
                Update
              </b-button>
            </b-col>
            <b-col
              md="6"
              class="mt-1"
              v-if="permissions.includes('product-category-delete')"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="danger"
                @click="deleteCategories()"
                block
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        category: "",
        selected: null,
        options: [],
      },
      permissions: [],
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getAllPermissions();
    this.getCategories();
    this.getDetails();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getCategories() {
      const id = this.$route.params.id;
      this.$http.get("product/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.options.push({ value: null, text: "Optional" });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.options.push($data);
        }
        // console.log(this.form.options)
      });
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http.get("product/category/" + id).then((response) => {
        // console.log(response.data.data)
        this.form.category = response.data.data.name;
        if (response.data.data.parent_id === "") {
          this.form.selected = null;
        } else {
          this.form.selected = response.data.data.parent_id;
        }
      });
    },
    updateCategory() {
      const id = this.$route.params.id;
      this.$http
        .post("product/category/" + id + "/update", {
          name: this.form.category,
          parent_id: this.form.selected,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success update Category",
              variant: "success",
            },
          });
          location.href = "/product/categories";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteCategories() {
      const id = this.$route.params.id;
      this.$http
        .post("product/category/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Category has been deleted",
              variant: "danger",
            },
          });
          location.href = "/product/categories";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>